
export const createErrorHTML = (customerDetail, errorMsg, cart) => {

  const html = `<div>
  <h2>An Error Occurred on the Payment Page</h2>
  <h3>Message: ${errorMsg}</h3>
  <h4>Customer Information</h4>
  <p>Name: ${customerDetail.firstName} ${customerDetail.lastName}</p>
  <p>Phone: ${customerDetail.phone}</p>
  <p>Email: ${customerDetail.email}</p>
  <h4>Cart Info</h4>
  ${cart.map(item => (
    `<p>${item.name} x${item.quantity}</p>`
  ))}
  </div>`;
  return html;
}