import axios from "axios";
import {
  AUTHORIZE,
  PRODUCTS,
  CREATE_ORDER,
  PAY_ORDER,
  CHECK_GIFT_CARD,
  PAYMENT,
  UPDATE_PAYMENT,
  PROMO,
  FIND_DISTANCE,
  SUBSCRIBE,
  SEND_EMAIL,
  SPREADSHEET,
  ADD_ORDER_ROW,
  ADD_EVENTS_ROW,
  SEND_ERROR_EMAIL
} from "../constants/services";

export const authorize = (params) => {
  return axios.post(AUTHORIZE, params);
};

export const getProducts = () => {
  return axios.get(PRODUCTS);
};

export const createOrder = (payload) => {
  return axios.post(CREATE_ORDER, payload);
};

export const payZeroOrder = (payload) => {
  return axios.post(PAY_ORDER, payload);
};

export const checkGiftCard = (payload) => {
  return axios.post(CHECK_GIFT_CARD, payload);
};

export const processPayment = (payload) => {
  return axios.post(PAYMENT, payload);
};
export const updatePayment = (payload) => {
  return axios.post(UPDATE_PAYMENT, payload);
};

export const checkPromo = (payload) => {
  return axios.post(PROMO, payload);
};

export const findDistance = (payload) => {
  return axios.post(FIND_DISTANCE, payload);
};

export const subscribeUser = (payload) => {
  return axios.post(SUBSCRIBE, payload);
};
export const sendEmail = (payload) => {
  return axios.post(SEND_EMAIL, payload);
};
export const getSpreadSheetData = (payload) => {
  return axios.post(SPREADSHEET, payload);
};
export const addOrderRow = (payload) => {
  return axios.post(ADD_ORDER_ROW, payload);
};
export const addEventsRow = (payload) => {
  return axios.post(ADD_EVENTS_ROW, payload);
};
export const sendErrorEmail = (payload) => {
  return axios.post(SEND_ERROR_EMAIL, payload);
};