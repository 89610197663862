let cardiganDonutsAPIUrl = process.env.REACT_APP_CARDIGAN_DONUTS;

export const AUTHORIZE = `${cardiganDonutsAPIUrl}/authorize`;
export const PRODUCTS = `${cardiganDonutsAPIUrl}/products`;
export const CREATE_ORDER = `${cardiganDonutsAPIUrl}/create-order`;
export const PAY_ORDER = `${cardiganDonutsAPIUrl}/pay-order`;
export const CHECK_GIFT_CARD = `${cardiganDonutsAPIUrl}/check-gift-card`;
export const PAYMENT = `${cardiganDonutsAPIUrl}/payment`;
export const UPDATE_PAYMENT = `${cardiganDonutsAPIUrl}/update-payment`;
export const PROMO = `${cardiganDonutsAPIUrl}/promo`;
export const FIND_DISTANCE = `${cardiganDonutsAPIUrl}/find-distance`;
export const SUBSCRIBE = `${cardiganDonutsAPIUrl}/subscribe`;
export const SEND_EMAIL = `${cardiganDonutsAPIUrl}/send-email`;
export const SPREADSHEET = `${cardiganDonutsAPIUrl}/spreadsheet`;
export const ADD_ORDER_ROW = `${cardiganDonutsAPIUrl}/add-order-row`;
export const ADD_EVENTS_ROW = `${cardiganDonutsAPIUrl}/add-events-row`;
export const SEND_ERROR_EMAIL = `${cardiganDonutsAPIUrl}/send-error-email`;