import outlinedMug from "../assets/icons/red-outlined/coffee_mug_red_outline.webp";
import filledMug from "../assets/icons/red-filled/coffee_mug_red_filled.webp";
import outlinedColdCup from "../assets/icons/red-outlined/cold_cup_red_outline.webp";
import filledColdCup from "../assets/icons/red-filled/cold_cup_red_filled.webp";
import outlinedDonut from "../assets/icons/red-outlined/donut_red_outline.webp";
import filledDonut from "../assets/icons/red-filled/donut_red_filled.webp";
import outlinedIceCream from "../assets/icons/red-outlined/ice_cream_red_outline.webp";
import filledIceCream from "../assets/icons/red-filled/ice_cream_red_filled.webp";
import outlinedYogurt from "../assets/icons/red-outlined/yogurt_red_outline.webp";
import filledYogurt from "../assets/icons/red-filled/yogurt_red_filled.webp";
import outlinedMugBlue from "../assets/icons/blue-outlined/coffee_mug_blue_outline.webp";
import filledMugBlue from "../assets/icons/blue-filled/coffee_mug_blue_filled.webp";
import outlinedColdCupBlue from "../assets/icons/blue-outlined/cold_cup_blue_outline.webp";
import filledColdCupBlue from "../assets/icons/blue-filled/cold_cup_blue_filled.webp";
import outlinedDonutBlue from "../assets/icons/blue-outlined/donut_blue_outline.webp";
import filledDonutBlue from "../assets/icons/blue-filled/donut_blue_filled.webp";
import outlinedIceCreamBlue from "../assets/icons/blue-outlined/ice_cream_blue_outline.webp";
import filledIceCreamBlue from "../assets/icons/blue-filled/ice_cream_blue_filled.webp";
import outlinedYogurtBlue from "../assets/icons/blue-outlined/yogurt_blue_outline.webp";
import filledYogurtBlue from "../assets/icons/blue-filled/yogurt_blue_filled.webp";
import { tags } from "./tags";
import { specificOrderTypes } from "./orderTypes";

// do not change the order of this array
export const MENU_ARRAY = [
  {
    category: "donuts",
    title: "Donuts + Boxes",
    ariaLabel: "See donuts menu",
    icon: outlinedDonut,
    activeIcon: filledDonut,
    mobileIcon: outlinedDonutBlue,
    mobileActiveIcon: filledDonutBlue,
    subCategories: [
      {
        subCategory: "Donut Boxes",
        title: "Available for same day pick-up, pre-order pick-up, and pre-order delivery",
        tags: [tags.none],
        orderTypes: [...Object.keys(specificOrderTypes).map(item => specificOrderTypes[item])],
        items: []
      },
      {
        subCategory: "Coffee to Go",
        title: "Available for same day pick-up, pre-order pick-up at IDS and City Center locations, and pre-order delivery",
        tags: [tags.none],
        orderTypes: [specificOrderTypes.sameCC, specificOrderTypes.sameIDS, specificOrderTypes.preDel, specificOrderTypes.prePickCC, specificOrderTypes.prePickIDS],
        items: []
      },
      {
        subCategory: "Inspired Donuts",
        title: "Available for same day pick-up, pre-order pick-up, and pre-order delivery",
        tags: [tags.none],
        orderTypes: [...Object.keys(specificOrderTypes).map(item => specificOrderTypes[item])],
        items: []
      },
      {
        subCategory: "Classic Donuts",
        title: "Available for same day pick-up, pre-order pick-up, and pre-order delivery",
        tags: [tags.none],
        orderTypes: [...Object.keys(specificOrderTypes).map(item => specificOrderTypes[item])],
        items: []
      },
      {
        subCategory: "Premium Donuts",
        title: "Available for same day pick-up, pre-order pick-up, and pre-order delivery",
        tags: [tags.none],
        orderTypes: [...Object.keys(specificOrderTypes).map(item => specificOrderTypes[item])],
        items: []
      }
    ]
  },
  {
    category: "yogurt-bowls",
    title: "Yogurt Bowls",
    ariaLabel: "See yogurt bowl menu",
    icon: outlinedYogurt,
    activeIcon: filledYogurt,
    mobileIcon: outlinedYogurtBlue,
    mobileActiveIcon: filledYogurtBlue,
    subCategories: [
      {
        subCategory: "Yogurt Bowls",
        title: "Available for same day pick-up, pre-order pick-up, and pre-order delivery",
        tags: [tags.none],
        orderTypes: [...Object.keys(specificOrderTypes).map(item => specificOrderTypes[item])],
        items: []
      }
    ]
  },
  {
    category: "coffee-+-tea",
    title: "Coffee + Tea",
    ariaLabel: "See coffee and tea menu",
    icon: outlinedMug,
    activeIcon: filledMug,
    mobileIcon: outlinedMugBlue,
    mobileActiveIcon: filledMugBlue,
    subCategories: [
      {
        subCategory: "Brewed Coffee + On-Tap",
        title: "Available for same day pick-up at IDS and City Center locations",
        tags: [tags.pickUp],
        orderTypes: [specificOrderTypes.sameCC, specificOrderTypes.sameIDS],
        items: []
      },
      {
        subCategory: "Espresso Drinks",
        title: "Available for same day pick-up at City Center location ONLY",
        tags: [tags.pickUp, tags.cityCenter],
        orderTypes: [specificOrderTypes.sameCC],
        items: []
      },
      {
        subCategory: "Tea Lattes & Steamers",
        title: "Available for same day pick-up at City Center location ONLY",
        tags: [tags.pickUp, tags.cityCenter],
        orderTypes: [specificOrderTypes.sameCC],
        items: []
      },
      {
        subCategory: "Loose Leaf Teas",
        title: "Available for same day pick-up at IDS and City Center locations",
        tags: [tags.pickUp],
        orderTypes: [specificOrderTypes.sameCC, specificOrderTypes.sameIDS],
        items: []
      }
    ]
  },
  {
    category: "craft-drinks",
    title: "Craft Drinks",
    ariaLabel: "See craft drinks and smoothie menu",
    icon: outlinedColdCup,
    activeIcon: filledColdCup,
    mobileIcon: outlinedColdCupBlue,
    mobileActiveIcon: filledColdCupBlue,
    subCategories: [
      {
        subCategory: "Craft Drinks",
        title: "Available for same day pick-up at IDS and City Center locations",
        tags: [tags.pickUp],
        orderTypes: [specificOrderTypes.sameCC, specificOrderTypes.sameIDS],
        items: []
      },
      {
        subCategory: "Plant-Based Smoothies",
        title: "Same day pickup at IDS only",
        tags: [tags.pickUp, tags.IDS,],
        orderTypes: [specificOrderTypes.sameIDS],
        items: []
      }
    ]
  },
  {
    category: "ice-cream",
    title: "Ice Cream",
    ariaLabel: "See ice cream menu",
    icon: outlinedIceCream,
    activeIcon: filledIceCream,
    mobileIcon: outlinedIceCreamBlue,
    mobileActiveIcon: filledIceCreamBlue,
    subCategories: [
      {
        subCategory: "Handmade Ice Cream",
        title: "Same day pickup at IDS only",
        tags: [tags.pickUp, tags.IDS],
        orderTypes: [specificOrderTypes.sameIDS],
        items: []
      },
      {
        subCategory: "Shakes + Malts",
        title: "Same day pickup at IDS only",
        tags: [tags.pickUp, tags.IDS,],
        orderTypes: [specificOrderTypes.sameIDS],
        items: []
      },
      {
        subCategory: "Ice Cream + Donut",
        title: "Same day pickup at IDS only",
        tags: [tags.pickUp, tags.IDS],
        orderTypes: [specificOrderTypes.sameIDS],
        items: []
      },
      {
        subCategory: "Wafel Sundae",
        title: "Same day pickup at IDS only",
        tags: [tags.pickUp, tags.IDS],
        orderTypes: [specificOrderTypes.sameIDS],
        items: []
      }
    ]
  }
]